<script setup>
import {computed, provide, ref, watch} from "vue";
import useEntry, {USE_ENTRY_KEY} from "../EntryComposition";
import ContainerLogin from "./ContainerLogin.vue";
import ContainerEmail from "./ContainerEmail.vue";
import BackgroundContainer from "../BackgroundContainer.vue";
import ContainerName from "./ContainerName.vue";
import ContainerChildren from "./ContainerChildren.vue";
import ContainerCategories from "./ContainerCategories.vue";
import ContainerConfirm from "./ContainerConfirm.vue";

const siteUrl = document.querySelector('meta[name="site-url"]').content;
const props = defineProps({
  modalType: {
    type: String,
    required: true,
  },
  initEmail: {
    type: String,
    default: '',
  },
  registerSuccess: {
    type: Boolean,
    required: true,
  },
  isFromComment: {
    type: Boolean,
    required: true,
  },
});
const emits = defineEmits(['request-change', 'request-send', 'request-support']);

const composition = useEntry(siteUrl);
provide(USE_ENTRY_KEY, composition);

// モーダルのタイトル
const modalTitle = computed(() => {
  switch (props.modalType) {
    case 'login':
      return props.isFromComment ? 'ログイン/アカウント登録が必要です。' : 'ログイン/アカウント登録';
    case 'email':
      return 'メール認証';
    case 'name':
    case 'children':
    case 'categories':
      return 'アカウント情報入力';
    case 'confirm':
      return '入力情報確認';
    default:
      return '';
  }
});

// モーダルの内容が変わる度にスクロール位置を先頭に戻す
watch(()=> props.modalType, ()=> {
  // スクロール位置を先頭に戻すための要素を確保
  const target = document.querySelector('.modDrawerModalBottomWrapper');

  // 見つからなかったらなにもしない
  if ( ! target) {
    return;
  }

  // 見つかったら要素内のスクロール位置を先頭へ
  target.scrollTop = 0;
});
</script>

<template>
  <div>
    <teleport to="body">
      <BackgroundContainer
        :is-show="modalType !== ''"
        @request-close="emits('request-change', '')"
      />
    </teleport>
    <teleport to="body">
      <div :class="{modDrawerModalWindow: true, dWide: true, isShow: modalType !== ''}">
        <div>
          <div class="modDrawerModalTitleContainer">
            <h4 class="pFont16 pFontBold">{{modalTitle}}</h4>
            <button
              type="button"
              class="lyHvCnt lyColumn lyW48px lyH48px pFont10 pFontBold"
              style="flex-grow: 0"
              @click="emits('request-change', '')"
            ><span class="lyBlock lyW24px"><img
            :src="siteUrl+'images/iconClose.svg'"
            loading="lazy"
            alt=""
            width="30"
            height="31"
          ></span>とじる</button>
          </div>
          <div class="modDrawerModalBottomWrapper">
            <ContainerLogin
              v-if="props.modalType === 'login'"
              :register-success="registerSuccess"
              :is-from-comment="props.isFromComment"
              @request-change="emits('request-change', $event)"
            />
            <ContainerEmail
              v-else-if="props.modalType === 'email'"
              :init-email="props.initEmail"
              @request-change="emits('request-change', $event)"
              @request-send="emits('request-send')"
            />
            <ContainerName
              v-else-if="props.modalType === 'name'"
              @request-change="emits('request-change', $event)"
              @request-support="emits('request-support', $event)"
            />
            <ContainerChildren
              v-else-if="props.modalType === 'children'"
              @request-change="emits('request-change', $event)"
              @request-support="emits('request-support', $event)"
            />
            <ContainerCategories
              v-else-if="props.modalType === 'categories'"
              @request-change="emits('request-change', $event)"
              @request-support="emits('request-support', $event)"
            />
            <ContainerConfirm
              v-else-if="props.modalType === 'confirm'"
              @request-change="emits('request-change', $event)"
            />
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>
