<script setup>
import {inject, ref, watch} from "vue";
import useEntry, {USE_ENTRY_KEY} from "../EntryComposition";

const props = defineProps({
  initEmail: {
    type: String,
    default: '',
  }
});
const emits = defineEmits(['request-send']);

// サイト URL
const siteUrl = document.querySelector('meta[name="site-url"]').content;
const composition = inject(USE_ENTRY_KEY, useEntry(siteUrl));

// メールアドレス入力値
const email = ref(props.initEmail);

// パスワード入力値
const password = ref('');

// パスワード再入力入力値
const passwordConfirm = ref('');

// 利用規約・プライバシーポリシーに同意するか
const agree = ref(false);

// エラー
const errors = ref({});

// バリデーション
const validation = async (name) => {
  // エラーを初期化
  errors.value = {...errors.value, [name]: null, message: null};

  // 送信パラメータを作成
  const form = new FormData();
  form.append('email', email.value);
  form.append('password', password.value);
  form.append('password_conf', passwordConfirm.value);

  // バリデーションをサーバに依頼
  try {
    // サーバに送信
    const response = await fetch(siteUrl+'entry/valid/'+name, {
      method: 'post',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
      },
      body: form
    });

    // 異常発生
    if ( ! response.ok) {
      //メッセージを JSON としてパース
      const json = await response.json();

      //もし errors が見つかったら例外を投げる
      if (
        typeof json.errors === 'object' &&
        json.errors !== null
      ) {
        throw json;
      }

      //もし message が見つかったら例外を投げる
      if (typeof json.message === 'string') {
        throw new Error(json.message);
      }

      //エラーメッセージを文字列に変換して例外を投げる
      throw new Error('エラーが発生しました。しばらくしてから再度お試しください。');
    }

    // 正常終了
    errors.value = {...errors, [name]: ''};
  }

  catch (error) {
    //サーバーから得られた各バリデーション
    if (
      typeof error.errors === 'object' &&
      error.errors !== null
    ) {
      errors.value = error.errors;
    }

    //恐らく通信途絶
    else if (error instanceof TypeError) {
      errors.value = {message: 'ネットワーク接続に失敗しました。'};
    }

    //恐らく意図的な中断
    else if (error instanceof DOMException) {
      errors.value = {message: 'ネットワーク接続が中断されました。'};
    }

    //恐らくカスタムエラー(try 節中、意図的に throw している部分)
    else if (error instanceof Error) {
      errors.value = {message: error.message};
    }

    //不明
    else {
      errors.value = {message: 'エラーが発生しました。しばらくしてから再度お試しください。'};
    }
  }
};

// 送信
const send = async () => {
  // エラーを初期化
  errors.value = {...errors.value, message: null};

  // 送信パラメータを作成
  const body = new FormData();
  body.append('email', email.value);
  body.append('password', password.value);
  body.append('password_conf', passwordConfirm.value);
  body.append('agree', agree.value ? '1' : '');

  //サーバーに問い合わせを行う
  try {
    //通信開始
    const response = await fetch(siteUrl+'entry/send', {
      method: 'post',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
      },
      body
    });

    //レスポンス異常発生
    if ( ! response.ok) {
      //メッセージを JSON としてパース
      const json = await response.json();

      //もし errors が見つかったら例外を投げる
      if (
        typeof json.errors === 'object' &&
        json.errors !== null
      ) {
        throw json;
      }

      //もし message が見つかったら例外を投げる
      if (typeof json.message === 'string') {
        throw new Error(json.message);
      }

      //エラーメッセージを文字列に変換して例外を投げる
      throw new Error('エラーが発生しました。しばらくしてから再度お試しください。');
    }

    //成功画面を表示
    emits('request-send');
  }

    //サーバーとの通信中、なんらかのエラーが発生
  catch (error) {
    console.log(error);
    //サーバーから得られた各バリデーション
    if (
      typeof error.errors === 'object' &&
      error.errors !== null
    ) {
      errors.value = error.errors;
    }

    //恐らく通信途絶
    else if (error instanceof TypeError) {
      errors.value = {message: 'ネットワーク接続に失敗しました。'};
    }

    //恐らく意図的な中断
    else if (error instanceof DOMException) {
      errors.value = {message: 'ネットワーク接続が中断されました。'};
    }

    //恐らくカスタムエラー(try 節中、意図的に throw している部分)
    else if (error instanceof Error) {
      errors.value = {message: error.message};
    }

    //不明
    else {
      errors.value = {message: 'エラーが発生しました。しばらくしてから再度お試しください。'};
    }
  }
};

// 初期値の変更を監視
watch(() => props.initEmail, (value) => {
  email.value = value;
});
</script>

<template>
  <div>
    <ol class="lyHorizontalCnt lyMb16">
      <li class="lyColumnHorizontalCnt lyW100px lyMr4 lyMl4 lyPb20">
        <p class="lyMb4 pFont24 pFontBold">1</p>
        <p class="pFont10 pFontBold">メール認証</p>
      </li>
      <li class="lyColumnHorizontalCnt lyW100px lyMr4 lyMl4 lyPb20 isDisabled">
        <p class="lyMb4 pFont24 pFontBold">2</p>
        <p class="pFont10 pFontBold">アカウント情報入力</p>
      </li>
      <li class="lyColumnHorizontalCnt lyW100px lyMr4 lyMl4 lyPb20 isDisabled">
        <p class="lyMb4 pFont24 pFontBold">3</p>
        <p class="pFont10 pFontBold">入力情報確認</p>
      </li>
    </ol>
    <div class="lyW100 lyPd16 pFont14 pBackColorWhite pBorderRadius4px">
      <div class="lyPt4 lyPb4">
        <p class="lyPt4 lyPb4">
          メールアドレスとパスワードをご入力お願いします。<br>
          ご登録いただきましたメールアドレスに登録URLを送信します。メールは自動で送信されます。<br>
          今後、ログインするときにメールアドレスとパスワードが必要になります。<br>
          ※SNS認証の場合、登録、ログインにパスワードは不要です。
        </p>
        <p class="lyPt4 lyPb4">
          ※メール受信制限をされている方は、@tetsunagi.co.jpからのメール受信を許可してください。
        </p>
        <p
          v-if="errors.message !== null && errors.message !== ''"
          class="lyMt4 pFont10 pFontMedium pFontRed"
        >{{errors.message}}</p>
      </div>
      <div class="lyPt12 lyPb12">
        <div class="lyPt4 lyPb4">
          <div :class="{pPopFormWrap: true, pFont16: true, isPositive: 'email' in errors && errors.email === '', isNegative: 'email' in errors && errors.email !== '' && errors.email !== null}">
            <input
              v-model="email"
              type="email"
              id="entryUserEmailAddress"
              class="pPopForm"
              placeholder=" "
              required=""
              @change="validation('email')"
            >
            <label for="entryUserEmailAddress" class="pPopFormLabel">メールアドレス（半角）</label>
          </div>
          <p
            v-if="'email' in errors"
            class="lyMt4 pFont10 pFontMedium pFontRed"
          >{{Array.isArray(errors.email) ? errors.email.join('、') : errors.email.toString()}}</p>
        </div>
        <div
          v-if="initEmail === ''"
          class="lyPt4 lyPb4"
        >
          <div :class="{pPopFormWrap: true, pFont16: true, isPositive: 'password' in errors && errors.password === '', isNegative: 'password' in errors && errors.password !== '' && errors.password !== null}">
            <input
              v-model="password"
              type="password"
              id="entryUserPassword"
              class="pPopForm"
              placeholder=" "
              required=""
              @change="validation('password')"
            >
            <label for="entryUserPassword" class="pPopFormLabel">パスワード（5文字以上の半角英数字）</label>
          </div>
          <p
            v-if="'password' in errors"
            class="lyMt4 pFont10 pFontMedium pFontRed"
          >{{Array.isArray(errors.password) ? errors.password.join('、') : errors.password.toString()}}</p>
        </div>
        <div
          v-if="initEmail === ''"
          class="lyPt4 lyPb4"
        >
          <div :class="{pPopFormWrap: true, pFont16: true, isPositive: 'password_conf' in errors && errors.password_conf === '', isNegative: 'password_conf' in errors && errors.password_conf !== '' && errors.password_conf !== null}">
            <input
              v-model="passwordConfirm"
              type="password"
              id="entryUserPassword"
              class="pPopForm"
              placeholder=" "
              required=""
              @change="validation('password_conf')"
            >
            <label for="entryUserPassword" class="pPopFormLabel">パスワード再入力（確認用）</label>
          </div>
          <p
            v-if="'password_conf' in errors"
            class="lyMt4 pFont10 pFontMedium pFontRed"
          >{{Array.isArray(errors.password_conf) ? errors.password_conf.join('、') : errors.password_conf.toString()}}</p>
        </div>
        <div v-else>
          <p
            v-if="'password' in errors"
            class="lyMt4 pFont10 pFontMedium pFontRed"
          >{{Array.isArray(errors.password) ? errors.password.join('、') : errors.password.toString()}}</p>
        </div>
      </div>
      <p class="lyPt4 lyPb4">
        <a
          :href="siteUrl+'terms'"
          target="_blank"
        >利用規約</a>・<a href="https://commitgrowth.co.jp/privacy_policy/" target="_blank">プライバシーポリシー</a>を読み、「同意する」をチェックお願いします。
        <span class="lyPd4 lyMl8 pFont10 pFontBold pBackColorGray4 pBorderRadius2px">必須</span>
      </p>
      <p
        v-if="'agree' in errors"
        class="lyMt4 pFont10 pFontMedium pFontRed"
      >{{Array.isArray(errors.agree) ? errors.agree.join('、') : errors.agree.toString()}}</p>
      <div class="lyPt12 lyPb12">
        <label class="lyHorizontal pCheckBox pHoverOpacityDefault pAnimation">
          <input
            v-model="agree"
            type="checkbox"
            name="agree"
          >
          <span class="pCheckBoxBtn"></span>
          <p class="lyMl8">利用規約・プライバシーポリシーに同意する</p>
        </label>
      </div>
      <p
        v-if="'message' in composition.errors.value && typeof composition.errors.value.message === 'string' && composition.errors.value.message !== ''"
        class="lyMt4 pFont10 pFontMedium pFontRed"
      >{{composition.errors.value.message}}</p>
      <p class="lyPt4 lyPb4 lyMaxW400px lyBlockCnt">
        <button
          type="button"
          class="lyHvCnt lyW100 lyPt12 lyPb12 lyTextCnt pFont14 pFontBold pFontWhite pBackColorGreen pBorderRadius2px pHoverSlideChange"
          @click="send"
        >送信</button>
      </p>
    </div>
  </div>
</template>
