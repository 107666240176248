import {reactive, ref, watch} from "vue";

const useEntry =  (siteUrl) => {

  // 入力値
  const inputs = reactive({
    name: '',
    year: '',
    month: '',
    interesting: [],
    prefecture: '',
    children_status: 0,
    is_public_children: true,
    is_public_prefecture: true,
    children: [],
    is_receive_mail_magazine: true,
    agree: true,
  });

  // 設定値
  const settings = reactive({
    email: '',
    categories: [],
    p_child:	0,
    p_pref:	0,
    p_int:	0,
  });

  // エラー
  const errors = ref({});

  // POST 更新のエラーを変換
  const _convertPostErrors = (_errors) => {
    // 恐らく通信途絶
    if (_errors instanceof TypeError) {
      errors.value = {'_': 'ネットワーク接続に失敗しました。'};
    }

    // 恐らく意図的な中断
    else if (_errors instanceof DOMException) {
      errors.value = {'_': 'ネットワーク接続が中断されました。'};
    }

    // 配列
    else if (Array.isArray(_errors)) {
      errors.value = {'_': _errors.join('、')};
    }

    // エラーがオブジェクトの場合
    else if (_errors instanceof Object) {
      // errors プロパティが存在した場合
      if (_errors.errors && _errors.errors instanceof Object) {
        // 値部分が文字列、または文字列の一次元配列である可能性があるので一次元配列に変換する
        errors.value = Object.keys(_errors.errors).reduce((acc, key) => {
          acc[key] = Array.isArray(_errors.errors[key]) ? _errors.errors[key] : [_errors.errors[key]];
          return acc;
        }, {});
      }

      // そうでない場合はそのまま使用
      else {
        errors.value = _errors;
      }
    }

    // エラーが文字列の場合
    else if (_errors instanceof String) {
      errors.value = {
        '_': _errors
      };
    }

    // その他の場合
    else {
      errors.value = {
        '_': _errors.toString(),
        '__': 'エラーが発生しました。',
        '___': '画面をスーパーリロードして下さい。'
      };
    }
  };

  // 初期値を取得
  const _syncSettings = async () => {
    try {
      const response = await fetch(siteUrl+'entry/settings');
      const data = await response.json();
      settings.email = data.email;
      settings.categories = data.categories;
      settings.p_child = data.p_child;
      settings.p_pref = data.p_pref;
      settings.p_int = data.p_int;
    }

    catch (e) {
      alert('エラーが発生しました。恐れ入りますが、画面をリロードしてください。');
    }
  };

  // 送信用 FormData を生成
  const _makeFormData = () => {
    const formData = new FormData();
    formData.append('name', inputs.name);
    formData.append('year', inputs.year);
    formData.append('month', inputs.month);
    formData.append('children_status', inputs.children_status);
    formData.append('is_public_children', inputs.is_public_children ? 1 : 0);
    formData.append('is_public_prefecture', inputs.is_public_prefecture ? 1 : 0);
    formData.append('is_receive_mail_magazine', inputs.is_receive_mail_magazine ? 1 : 0);
    if (inputs.agree) {
      formData.append('agree', 1);
    }
    if (inputs.prefecture !== '') {
      formData.append('prefecture', inputs.prefecture);
    }
    inputs.children.forEach((child, index) => {
      formData.append('children['+index+'][sex]', child.sex);
      formData.append('children['+index+'][year]', child.year);
      formData.append('children['+index+'][month]', child.month);
    });
    inputs.interesting.forEach((interesting, index) => {
      formData.append('interesting['+index+']', interesting);
    });
    return formData;
  };

  // サーバにバリデーションを依頼する
  const validate = async () => {
    // エラーをクリア
    errors.value = {};

    // サーバにバリデーションを依頼
    try {
      const response = await fetch(siteUrl+'entry/check', {
        method: 'post',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
        },
        body: _makeFormData(),
      });

      // レスポンスをパース
      const data = await response.json();

      // 成功ならエラーをクリア
      if (response.ok) {
        return true;
      }

      // 失敗ならパースしてエラーをセット
      else {
        _convertPostErrors(data);
        return false;
      }
    }

      // エラーをキャッチ
    catch (e) {
      _convertPostErrors(e);
      return false;
    }
  };

  // サーバに登録を依頼する
  const register = async () => {
    // エラーをクリア
    errors.value = {};

    // サーバにバリデーションを依頼
    try {
      const response = await fetch(siteUrl+'entry/register', {
        method: 'post',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
        },
        body: _makeFormData(),
      });

      // レスポンスをパース
      const data = await response.json();

      // 成功
      if (response.ok) {
        return true;
      }

      // 失敗ならパースしてエラーをセット
      else {
        _convertPostErrors(data);
        return false;
      }
    }

      // エラーをキャッチ
    catch (e) {
      _convertPostErrors(e);
      return false;
    }
  };

  // 初期値を同期
  _syncSettings();

  // 子供の状態が「いる」に変わったら子供を追加
  watch(() => inputs.children_status, (to) => {
    if (Number(to) === 1 && inputs.children.length === 0) {
      const now = new Date();
      inputs.children.push({
        year: '',
        month: '',
        sex: 0
      });
    }
  });

  // 提供
  return {
    inputs,
    settings,
    errors,
    validate,
    register,
  };
};

export default useEntry;

// composition を注入するためのキー
export const USE_ENTRY_KEY = Symbol('useEntry');
