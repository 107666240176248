<script setup>
import {inject} from "vue";
import useEntry, {USE_ENTRY_KEY} from "../EntryComposition";

const siteUrl = document.querySelector('meta[name="site-url"]').content;
const emits = defineEmits(['request-support', 'request-change']);

const composition = inject(USE_ENTRY_KEY, useEntry(siteUrl));

// 興味のある分野を付け外し
const toggleInteresting = (id, checked) => {
  // 既に存在するか確認
  const index = composition.inputs.interesting.indexOf(id);

  // チェックが付いたかつ、存在しない場合は追加
  if (checked && index === -1) {
    composition.inputs.interesting.push(id);
  }

  // チェックが外れたかつ、存在する場合は削除
  else if ( ! checked && index !== -1) {
    composition.inputs.interesting.splice(index, 1);
  }
};

// 最終確認ページへ
const skip = async () => {
  // バリデーションを行う
  await composition.validate();

  // エラーがある場合は処理を中断
  if (Object.keys(composition.errors.value).length > 0) {
    return;
  }

  // 次ページへ
  emits('request-change', 'confirm');
};

// 次ページへ
const next = async () => {
  // エラーを初期化
  composition.errors.value = {};

  // バリデーションを行う
  await composition.validate();

  // エラーがある場合は処理を中断
  if (Object.keys(composition.errors.value).length > 0) {
    return;
  }

  // 次ページへ
  emits('request-change', 'confirm');
};
</script>

<template>
  <div>
    <ol class="lyHorizontalCnt lyMb16">
      <li class="lyColumnHorizontalCnt lyW100px lyMr4 lyMl4 lyPb20 isDisabled">
        <p class="lyMb4 pFont24 pFontBold">1</p>
        <p class="pFont10 pFontBold">メール認証</p>
      </li>
      <li class="lyColumnHorizontalCnt lyW100px lyMr4 lyMl4 lyPb20">
        <p class="lyMb4 pFont24 pFontBold">2</p>
        <p class="pFont10 pFontBold">アカウント情報入力</p>
      </li>
      <li class="lyColumnHorizontalCnt lyW100px lyMr4 lyMl4 lyPb20 isDisabled">
        <p class="lyMb4 pFont24 pFontBold">3</p>
        <p class="pFont10 pFontBold">入力情報確認</p>
      </li>
    </ol>
    <div class="lyW100 lyPd16 pFont14 pBackColorWhite pBorderRadius4px">
      <div class="lyPt4 lyPb4 lyMb32">
        <div class="lyHorizontalEnd">
          <button
            type="button"
            class="lyPt4 lyPb4 pFont12 pFontMedium pFontGreen"
            @click="skip"
          >今は入力をスキップ</button>
        </div>
        <div class="lyPt8 lyPb8">
          <button
            type="button"
            class="lyVerticalCnt lyW100 lyPt8 lyPb8 lyPl8 pFont12 pBackColorGray4"
            @click="emits('request-support', 'bonus')"
          >
            <span class="lyBlock lyW16px lyMr4"><img
              :src="siteUrl+'images/iconInformationBlue.svg'"
              width="16"
              height="16"
              loading="lazy"
              alt=""
            ></span>
            初回情報登録すると各ボーナスポイントゲット！
          </button>
        </div>
        <dl>
          <dt class="lyPt4 lyPb4 lyVerticalBwCnt">
            <div class="lyVerticalCnt">
              <label class="lyMr4 pFont12 pFontMedium">興味のあるカテゴリーを選択</label>
              <span class="lyPd4 lyMr8 pFont10 pFontBold pBackColorGray4 pBorderRadius2px">任意</span>
              <p class="pFont12 pFontBold pFontRed">初回+{{new Intl.NumberFormat().format(composition.settings.p_int * 2)}}P</p>
            </div>
          </dt>
          <dd class="lyPt4 lyPb4">
            <p
              v-for="error in ('interesting' in composition.errors.value ? composition.errors.value.interesting.filter(_error => _error !== '' && _error !== null) : [])"
              :key="error"
              class="lyMb8 pFont12 pFontBold pFontRed"
            >{{error}}</p>
            <ul class="lyHorizontalBreak">
              <li
                v-for="category in composition.settings.categories"
                :key="category.id"
                class="lyMr8 lyMt8 lyMb8"
              >
                <label class="lyInlineBlock modEllipseChip dGreen">
                  <input
                    :checked="composition.inputs.interesting.includes(category.id)"
                    :value="category.id"
                    type="checkbox"
                    name="interesting"
                    @change="toggleInteresting(category.id, $event.target.checked)"
                  >
                  <span class="lyInlineBlock lyPt6 lyPb6 lyPr8 lyPl8 pFont14 pFontBold pFontWhite pBackColorGray30 pBorderRadiusEllipse pHoverOpacityDefault modEllipseChipBtn">{{category.name}}</span>
                </label>
              </li>
            </ul>
          </dd>
        </dl>
      </div>
      <p
        v-if="'message' in composition.errors.value && typeof composition.errors.value.message === 'string' && composition.errors.value.message !== ''"
        class="lyMt4 pFont10 pFontMedium pFontRed"
      >{{composition.errors.value.message}}</p>
      <div class="lyHorizontalBwBreak lyFlexItemBreakBan lyTbFlexItemHorizontalRev lyPt4 lyPb4">
        <p class="lyW100 lyTbW50 lyMb16 lyTbMl8 lyTbMb0">
          <button
            type="submit"
            class="lyHvCnt lyW100 lyPt12 lyPb12 lyTextCnt pFont14 pFontBold pFontWhite pBackColorGreen pBorder1pxSolidGreen pBorderRadius2px pHoverSlideChange"
            @click="next"
          >次へ
          </button>
        </p>
        <p class="lyW100 lyTbW50 lyTbMr8">
          <button
            type="button"
            class="lyHvCnt lyW100 lyPt12 lyPb12 lyTextCnt pFont14 pFontBold pFontGreen pBorder1pxSolidGreen pBorderRadius2px"
            @click="emits('request-change', 'children')"
          >戻って編集
          </button>
        </p>
      </div>
    </div>
  </div>
</template>
