<script setup>
import BackgroundContainer from "../BackgroundContainer.vue";

const siteUrl = document.querySelector('meta[name="site-url"]').content;
const props = defineProps({
  isShow: {
    type: Boolean,
    required: true,
  }
});
const emits = defineEmits(['request-close']);
</script>

<template>
  <teleport to="body">
    <BackgroundContainer
      :is-show="isShow"
      :layer-depth="3"
      @request-close="emits('request-close')"
    />
  </teleport>
  <teleport to="body">
    <div
      :class="{modDrawerModalWindow: true, dWide: true, isShow: isShow}"
      style="z-index:303;height:50vh;"
    >
      <div class="modModalTitleContainer">
        <p class="lyVerticalCnt pFont16 pFontBold pFontBlue">
        <span class="lyBlock lyW16px lyMr4"><img
          :src="siteUrl+'images/iconHelpBlue.svg'"
          width="16"
          height="16"
          loading="lazy"
          alt=""
        ></span>
          ボーナスポイント
        </p>
        <button
          type="button"
          class="lyHvCnt lyColumn lyW48px lyH48px pFont10 pFontBold"
          @click="emits('request-close')"
        >
        <span class="lyBlock lyW24px"><img
          :src="siteUrl+'images/iconClose.svg'"
          loading="lazy"
          alt=""
          width="30"
          height="31"
        ></span>
          とじる
        </button>
      </div>
      <div class="modModalBottomWrapper">
        <div class="lyPd16">
          <p class="lyMb16 pFont14">各項目を入力するとボーナスポイントがもらえて、抽選1回分のポイントがもらえます！<br>
            情報入力で抽選を回して、景品ゲットちゃおう☆</p>
        </div>
      </div>
    </div>
  </teleport>
</template>
