<script setup>
import {computed, ref, onMounted} from "vue";

const siteUrl = document.querySelector('meta[name="site-url"]').content;
const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  sex: {
    type: String,
    required: true,
  },
  year: {
    type: String,
    required: true,
  },
  month: {
    type: Number,
    required: true,
  },
  errors: {
    type: Object,
    required: true,
  }
});
const emits = defineEmits(['update-sex', 'update-year', 'update-month', 'delete-child']);

// 選択ボックスの表示切替
const isShowSelects = ref(false);

// このリストに関連のあるエラーメッセージを取得
const errorMessages = computed(() => 'children.'+props.index in props.errors ? props.errors['children.'+props.index] : []);

// sex を性別文言に変換
const sexTitle = computed(() => {
  switch (props.sex) {
    case 1:
      return '男の子';
    case 2:
      return '女の子';
    default:
      return '未選択';
  }
});

// 疑似セレクトボックス外の要素をクリックした際、疑似セレクトボックスを非表示にする、
onMounted(() => {
  document.addEventListener('click', (event) => {
    if ( ! event.target.closest('.modSelectTrigger')) {
      isShowSelects.value = false;
    }
  });
});
</script>

<template>
  <div>
    <dt class="lyPt8 lyPb8 lyVerticalCnt">
      <label
        :for="'childSex'+index"
        class="lyMr8 pFont12 pFontMedium"
      ><span class="lyW24px lyMr4">{{index + 1}}</span>人目</label>
      <p class="lyMr32 pFont12 pFontMedium" style="width: 80px">性別</p>
      <p class="lyMr32 pFont12 pFontMedium" style="width: 195px;">生年月</p>
      <p class="lyMr32 pFont12 pFontMedium" style="width: 80px;">取消ボタン</p>
    </dt>
    <dd>
      <p
        v-for="message in errorMessages"
        :key="message"
        class="lyMb8 pFont12 pFontBold pFontRed"
      >{{message}}</p>
      <div class="lyPt4 lyPb4 lyVerticalCnt">
        <div class="lyW100 lyTbW150px lyMr8">
          <div :class="{modSelectTrigger: true, isShow: isShowSelects}">
            <button
              :id="'childSex'+index"
              type="button"
              class="lyPt12 lyPb12 lyPr8 lyPl8 lyVerticalBwCnt lyTextLeft lyW100 pFont16 pFontMedium pBackColorWhite pBorder1pxSolidGray20 pBorderRadius2px"
              @click="isShowSelects = ! isShowSelects"
            >{{sexTitle}}<span class="lyW10px"><img
              :src="siteUrl+'images/iconSelectBoxTriangle.svg'"
              loading="lazy"
              alt="ここを押して選択"
            ></span></button>
            <div class="modSelectListContainer">
              <ul class="modSelectList">
                <li class="pBorderBottom1pxSolidGray4">
                  <label class="lyW100 lyPt12 lyPb12 lyPr8 lyPl8 lyTextCnt pFontBold pHoverBackGray4 pAnimation pCursorPointer">
                    <input
                      :checked="sex === 0"
                      :name="'childgender'+index"
                      type="radio"
                      value="0"
                      @change="emits('update-sex', 0), isShowSelects = false"
                    >
                    未選択
                  </label>
                </li>
                <li class="pBorderBottom1pxSolidGray4">
                  <label class="lyW100 lyPt12 lyPb12 lyPr8 lyPl8 lyTextCnt pFontBold pHoverBackGray4 pAnimation pCursorPointer">
                    <input
                      :checked="sex === 1"
                      :name="'childgender'+index"
                      type="radio"
                      value="1"
                      @change="emits('update-sex', 1), isShowSelects = false"
                    >
                    男の子
                  </label>
                </li>
                <li>
                  <label class="lyW100 lyPt12 lyPb12 lyPr8 lyPl8 lyTextCnt pFontBold pHoverBackGray4 pAnimation pCursorPointer">
                    <input
                      :checked="sex === 2"
                      :name="'childgender'+index"
                      type="radio"
                      value="2"
                      @change="emits('update-sex', 2), isShowSelects = false"
                    >
                    女の子
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="lyVerticalCnt lyMr8">
          <div class="lyW100px">
            <input
              :value="year"
              type="number"
              class="lyPt12 lyPb12 lyPr8 lyPl8 lyTextRight lyW100 pFont16 pFontMedium pBackColorWhite pBorder1pxSolidGray20 pBorderRadius2px"
              placeholder="例:2020"
              @change="emits('update-year', $event.target.value)"
            >
          </div>
          <p class="lyMl8 pFont12 pFontMedium">年</p>
        </div>
        <div class="lyVerticalCnt lyMr16">
          <div class="lyW60px">
            <input
              :value="month"
              type="number"
              class="lyPt12 lyPb12 lyPr8 lyPl8 lyTextRight lyW100 pFont16 pFontMedium pBackColorWhite pBorder1pxSolidGray20 pBorderRadius2px"
              placeholder="例:01"
              @change="emits('update-month', $event.target.value)"
            >
          </div>
          <p class="lyMl8 pFont12 pFontMedium">月</p>
        </div>
        <button
          type="button"
          class="lyPd2 pBackColorGray50 pBorderRadiusEllipse"
          @click="emits('delete-child')"
        ><span class="lyBlock lyW16px"><img
          :src="siteUrl+'images/iconCloseWhite.svg'"
          alt="削除"
        ></span></button>
      </div>
    </dd>
  </div>
</template>
