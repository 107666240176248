<script setup>
import BackgroundContainer from "../BackgroundContainer.vue";

const siteUrl = document.querySelector('meta[name="site-url"]').content;
const props = defineProps({
  isShow: {
    type: Boolean,
    required: true
  }
});
const emits = defineEmits(['request-close']);
</script>

<template>
  <div>
    <teleport to="body">
      <BackgroundContainer
        :is-show="isShow"
        :layer-depth="3"
        @request-close="emits('request-close')"
      />
    </teleport>
    <teleport to="body">
      <div
        :class="{modModalWindow: true, mdPopUp: true, isShow: isShow}"
        style="z-index: 304"
      >
        <div class="modModalTitleContainer">
          <p class="pFont16 pFontBold">メール認証ありがとうございます</p>
          <button
            type="button"
            class="lyHvCnt lyColumn lyW48px lyH48px pFont10 pFontBold"
            @click="emits('request-close')"
          >
            <span class="lyBlock lyW24px"><img
              :src="siteUrl+'images/iconClose.svg'"
              loading="lazy"
              alt=""
              width="30"
              height="31"
            ></span>
            とじる
          </button>
        </div>
        <div class="modModalBottomWrapper">
          <div class="lyPd16">
            <p class="lyMb16 pFont14">届いたメールに記載されている URL から、ニックネームと生年月を入力してください。</p>
          </div>
          <div class="lyMtAuto">
            <div class="lyHorizontal pBorderTop2pxSolidGray4">
              <p class="lyW100">
                <button
                  type="button"
                  class="lyW100 lyPt16 lyPb16 pFont14 pFontBold lyTextCnt"
                  @click="emits('request-close')"
                >閉じる</button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>
