<script setup>
import {inject, watch} from "vue";
import useEntry, {USE_ENTRY_KEY} from "../EntryComposition";

const siteUrl = document.querySelector('meta[name="site-url"]').content;
const emits = defineEmits(['request-support', 'request-change']);

const composition = inject(USE_ENTRY_KEY, useEntry(siteUrl));

// 次ページへ
const next = async () => {
  // バリデーションを行う
  await composition.validate();

  // エラーがある場合は処理を中断
  if (Object.keys(composition.errors.value).length > 0) {
    return;
  }

  // 次ページへ
  emits('request-change', 'children');
};
</script>

<template>
  <div>
    <ol class="lyHorizontalCnt lyMb16">
      <li class="lyColumnHorizontalCnt lyW100px lyMr4 lyMl4 lyPb20 isDisabled">
        <p class="lyMb4 pFont24 pFontBold">1</p>
        <p class="pFont10 pFontBold">メール認証</p>
      </li>
      <li class="lyColumnHorizontalCnt lyW100px lyMr4 lyMl4 lyPb20">
        <p class="lyMb4 pFont24 pFontBold">2</p>
        <p class="pFont10 pFontBold">アカウント情報入力</p>
      </li>
      <li class="lyColumnHorizontalCnt lyW100px lyMr4 lyMl4 lyPb20 isDisabled">
        <p class="lyMb4 pFont24 pFontBold">3</p>
        <p class="pFont10 pFontBold">入力情報確認</p>
      </li>
    </ol>
    <div class="lyW100 lyPd16 pFont14 pBackColorWhite pBorderRadius4px">
      <div class="lyPt4 lyPb4 lyMb32">
        <dl>
          <dt class="lyPt4 lyPb4 lyVerticalBwCnt">
            <div class="lyVerticalCnt lyW100">
              <p class="lyMr4 pFont12 pFontMedium">ニックネーム</p>
              <span class="lyPd4 lyMr8 pFont10 pFontBold pBackColorGray4 pBorderRadius2px">必須</span>
              <button
                type="button"
                class="lyVerticalCnt lyMlAuto pFontBlue pFont12 pFontMedium"
                @click="emits('request-support', 'name')"
              >
                <span class="lyBlock lyW16px lyMr4"><img
                  :src="siteUrl+'images/iconHelpBlue.svg'"
                  width="16"
                  height="16"
                  loading="lazy"
                  alt=""
                ></span>
                ニックネームについて
              </button>
            </div>
          </dt>
          <dd class="lyPt4 lyPb4 lyMb24">
            <div :class="{pPopFormWrap: true, pFont16: true, isPositive: 'name' in composition.errors.value && composition.errors.value.name === '', isNegative: 'name' in composition.errors.value && composition.errors.value.name !== '' && composition.errors.value.name !== null}">
              <input
                v-model="composition.inputs.name"
                type="text"
                id="entryUserNickName"
                class="pPopForm"
                placeholder=" "
                required=""
                @change="composition.validate"
              >
              <label for="entryUserNickName" class="pPopFormLabel">全角ひらがな・半角英数字・記号</label>
            </div>
            <p
              v-for="error in ('name' in composition.errors.value ? composition.errors.value.name.filter(_error => _error !== '' && _error !== null) : [])"
              :key="error"
              class="lyMt4 pFont10 pFontMedium pFontRed"
            >{{error}}</p>
          </dd>
          <dt class="lyPt4 lyPb4 lyVerticalBwCnt">
            <div class="lyVerticalCnt lyW100">
              <p class="lyMr4 pFont12 pFontMedium">生年月（半角数字）</p>
              <span class="lyPd4 lyMr8 pFont10 pFontBold pBackColorGray4 pBorderRadius2px">必須</span>
            </div>
          </dt>
          <dd class="lyPt4 lyPb4">
            <div class="lyVerticalCnt">
              <div class="lyVerticalCnt lyMr8">
                <div class="lyW60px">
                  <input
                    v-model="composition.inputs.year"
                    type="number"
                    class="lyPt12 lyPb12 lyPr8 lyPl8 lyTextRight lyW100 pFont16 pFontMedium pBackColorWhite pBorder1pxSolidGray20 pBorderRadius2px"
                    placeholder="2000"
                    required=""
                    @change="composition.validate"
                  >
                </div>
                <p class="lyMl8 pFont12 pFontMedium">年</p>
              </div>
              <div class="lyVerticalCnt">
                <div class="lyW60px">
                  <input
                    v-model="composition.inputs.month"
                    type="number"
                    class="lyPt12 lyPb12 lyPr8 lyPl8 lyTextRight lyW100 pFont16 pFontMedium pBackColorWhite pBorder1pxSolidGray20 pBorderRadius2px"
                    placeholder="01"
                    required=""
                    @change="composition.validate"
                  >
                </div>
                <p class="lyMl8 pFont12 pFontMedium">月</p>
              </div>
            </div>
            <p
              v-for="error in ('year' in composition.errors.value ? composition.errors.value.year.filter(_error => _error !== '' && _error !== null) : [])"
              :key="error"
              class="lyMt4 pFont10 pFontMedium pFontRed"
            >{{error}}</p>
            <p
              v-for="error in ('month' in composition.errors.value ? composition.errors.value.month.filter(_error => _error !== '' && _error !== null) : [])"
              :key="error"
              class="lyMt4 pFont10 pFontMedium pFontRed"
            >{{error}}</p>
          </dd>
        </dl>
      </div>
      <p
        v-if="'message' in composition.errors.value && typeof composition.errors.value.message === 'string' && composition.errors.value.message !== ''"
        class="lyMt4 pFont10 pFontMedium pFontRed"
      >{{composition.errors.value.message}}</p>
      <p class="lyPt4 lyPb4 lyMaxW400px lyBlockCnt">
        <button
          type="submit"
          class="lyHvCnt lyW100 lyPt12 lyPb12 lyTextCnt pFont14 pFontBold pFontGreen pBorder1pxSolidGreen pBorderRadius2px"
          @click="next"
        >登録する</button>
      </p>
    </div>
  </div>
</template>
