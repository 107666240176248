<script setup>
import {inject, onMounted, ref} from "vue";
import useEntry, {USE_ENTRY_KEY} from "../EntryComposition";
import ChildrenList from "./ChildrenList.vue";

const siteUrl = document.querySelector('meta[name="site-url"]').content;
const emits = defineEmits(['request-support', 'request-change']);

const composition = inject(USE_ENTRY_KEY, useEntry(siteUrl));

// 都道府県
const PREFECTURES = ['北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'];

// 現在時刻を取得
const now = new Date();

// 選択ボックスの表示切替
const isShowSelects = ref(false);

// children を追加
const addChild = () => {
  composition.inputs.children.push({
    year: '',
    month: '',
    sex: 0
  });
};

// children を削除
const deleteChild = (index) => {
  composition.inputs.children.splice(index, 1);
};

// children を更新
const updateChild = (index, key, value) => {
  composition.inputs.children.splice(index, 1, {
    ...composition.inputs.children[index],
    [key]: value
  });
};

// 最終確認ページへ
const skip = async () => {
  // バリデーションを行う
  await composition.validate();

  // エラーがある場合は処理を中断
  if (Object.keys(composition.errors.value).length > 0) {
    return;
  }

  // 次ページへ
  emits('request-change', 'confirm');
};

// 次ページへ
const next = async () => {
  // エラーを初期化
  composition.errors.value = {};

  // バリデーションを行う
  await composition.validate();

  // エラーがある場合は処理を中断
  if (Object.keys(composition.errors.value).length > 0) {
    return;
  }

  // 次ページへ
  emits('request-change', 'categories');
};

// 疑似セレクトボックス外の要素をクリックした際、疑似セレクトボックスを非表示にする、
onMounted(() => {
  document.addEventListener('click', (event) => {
    if ( ! event.target.closest('.modSelectTrigger')) {
      isShowSelects.value = false;
    }
  });
});
</script>

<template>
  <div>
    <ol class="lyHorizontalCnt lyMb16">
      <li class="lyColumnHorizontalCnt lyW100px lyMr4 lyMl4 lyPb20 isDisabled">
        <p class="lyMb4 pFont24 pFontBold">1</p>
        <p class="pFont10 pFontBold">メール認証</p>
      </li>
      <li class="lyColumnHorizontalCnt lyW100px lyMr4 lyMl4 lyPb20">
        <p class="lyMb4 pFont24 pFontBold">2</p>
        <p class="pFont10 pFontBold">アカウント情報入力</p>
      </li>
      <li class="lyColumnHorizontalCnt lyW100px lyMr4 lyMl4 lyPb20 isDisabled">
        <p class="lyMb4 pFont24 pFontBold">3</p>
        <p class="pFont10 pFontBold">入力情報確認</p>
      </li>
    </ol>
    <div class="lyW100 lyPd16 pFont14 pBackColorWhite pBorderRadius4px">
      <div class="lyPt4 lyPb4 lyMb32">
        <div class="lyHorizontalEnd">
          <button
            type="button"
            class="lyPt4 lyPb4 pFont12 pFontMedium pFontGreen"
            @click="skip"
          >今は入力をスキップ</button>
        </div>
        <div class="lyPt8 lyPb8">
          <button
            type="button"
            class="lyVerticalCnt lyW100 lyPt8 lyPb8 lyPl8 pFont12 pBackColorGray4"
            @click="emits('request-support', 'bonus')"
          ><span class="lyBlock lyW16px lyMr4"><img
              :src="siteUrl+'images/iconInformationBlue.svg'"
              width="16"
              height="16"
              loading="lazy"
              alt=""
            ></span>初回情報登録すると各ボーナスポイントゲット！</button>
        </div>
        <dl>
          <dt class="lyPt4 lyPb4 lyVerticalBwCnt">
            <div class="lyVerticalCnt">
              <p class="lyMr4 pFont12 pFontMedium">子ども情報</p>
              <span class="lyPd4 lyMr8 pFont10 pFontBold pBackColorGray4 pBorderRadius2px">任意</span>
              <p class="pFont12 pFontBold pFontRed">初回+{{new Intl.NumberFormat().format(composition.settings.p_child * 2)}}P</p>
            </div>
            <label
              for="childPublic"
              class="pSwitch lyVerticalCnt"
            >
              <input
                v-model="composition.inputs.is_public_children"
                type="checkbox"
                name="child"
                id="childPublic"
              >
              <div class="lyOrder1 pSwitchBtn"></div>
              <p class="lyOrder0 lyMr4 pSwitchTitle pFont12 pFontBold">公開</p>
              <p class="lyOrder2 lyMl4 pSwitchTitle pFont12 pFontBold">非公開</p>
            </label>
          </dt>
          <dd class="lyPt4 lyPb4">
            <p
              v-for="error in ('children_status' in composition.errors.value ? composition.errors.value.children_status.filter(_error => _error !== '' && _error !== null) : [])"
              :key="error"
              class="lyMb8 pFont12 pFontBold pFontRed"
            >{{error}}</p>
            <ul class="lyHorizontalBreak">
              <li class="lyMr8 lyMt8">
                <label class="lyInlineBlock lyMb16 modEllipseChip dGreen">
                  <input
                    v-model="composition.inputs.children_status"
                    type="radio"
                    name="userChildStatus"
                    value="0"
                  >
                  <span class="lyInlineBlock lyPt6 lyPb6 lyPr8 lyPl8 pFont14 pFontBold pFontWhite pBackColorGray30 pBorderRadiusEllipse pHoverOpacityDefault modEllipseChipBtn">未回答</span>
                </label>
              </li>
              <li class="lyMr8 lyMt8">
                <label class="lyInlineBlock lyMb16 modEllipseChip dGreen">
                  <input
                    v-model="composition.inputs.children_status"
                    type="radio"
                    name="userChildStatus"
                    value="1"
                  >
                  <span class="lyInlineBlock lyPt6 lyPb6 lyPr8 lyPl8 pFont14 pFontBold pFontWhite pBackColorGray30 pBorderRadiusEllipse pHoverOpacityDefault modEllipseChipBtn">いる</span>
                </label>
              </li>
              <li class="lyMr8 lyMt8">
                <label class="lyInlineBlock lyMb16 modEllipseChip dGreen">
                  <input
                    v-model="composition.inputs.children_status"
                    type="radio"
                    name="userChildStatus"
                    value="2"
                  >
                  <span class="lyInlineBlock lyPt6 lyPb6 lyPr8 lyPl8 pFont14 pFontBold pFontWhite pBackColorGray30 pBorderRadiusEllipse pHoverOpacityDefault modEllipseChipBtn">いない</span>
                </label>
              </li>
              <li class="lyMr8 lyMt8">
                <label class="lyInlineBlock lyMb16 modEllipseChip dGreen">
                  <input
                    v-model="composition.inputs.children_status"
                    type="radio"
                    name="userChildStatus"
                    value="3"
                  >
                  <span class="lyInlineBlock lyPt6 lyPb6 lyPr8 lyPl8 pFont14 pFontBold pFontWhite pBackColorGray30 pBorderRadiusEllipse pHoverOpacityDefault modEllipseChipBtn">妊娠中</span>
                </label>
              </li>
              <li class="lyMr8 lyMt8">
                <label class="lyInlineBlock lyMb16 modEllipseChip dGreen">
                  <input
                    v-model="composition.inputs.children_status"
                    type="radio"
                    name="userChildStatus"
                    value="4"
                  >
                  <span class="lyInlineBlock lyPt6 lyPb6 lyPr8 lyPl8 pFont14 pFontBold pFontWhite pBackColorGray30 pBorderRadiusEllipse pHoverOpacityDefault modEllipseChipBtn">妊活中</span>
                </label>
              </li>
            </ul>
            <dl v-if="Number(composition.inputs.children_status) === 1">
              <ChildrenList
                v-for="(child, index) in composition.inputs.children"
                :index="index"
                :month="String(child.month)"
                :year="String(child.year)"
                :sex="Number(child.sex)"
                :errors="composition.errors.value"
                @update-sex="updateChild(index, 'sex', $event)"
                @update-month="updateChild(index, 'month', $event)"
                @update-year="updateChild(index, 'year', $event)"
                @delete-child="deleteChild(index)"
              />
            </dl>
            <div
              v-if="Number(composition.inputs.children_status) === 1"
              class="lyPt16 lyPb16"
            >
              <button
                type="button"
                class="lyHvCnt lyW100 lyPt8 lyPb8 lyTextCnt pFont14 pFontBold pFontBlack pBackColorGray4 pBorder1pxSolidBlack pBorderRadius2px"
                @click="addChild"
              >
										<span class="lyBlock lyW16px lyMr8">
											<img
                        :src="siteUrl+'images/iconListAdd.svg'"
                        width="16"
                        height="16"
                        loading="lazy"
                        alt=""
                      >
										</span>
                子ども情報を追加
              </button>
            </div>
          </dd>
          <dt class="lyPt4 lyPb4 lyVerticalBwCnt">
            <div class="lyVerticalCnt">
              <label
                for="pref"
                class="lyMr4 pFont12 pFontMedium"
              >住まい</label>
              <span class="lyPd4 lyMr8 pFont10 pFontBold pBackColorGray4 pBorderRadius2px">任意</span>
              <p class="pFont12 pFontBold pFontRed">初回+{{new Intl.NumberFormat().format(composition.settings.p_pref * 2)}}P</p>
            </div>
            <label
              for="prefPublic"
              class="pSwitch lyVerticalCnt"
            >
              <input
                v-model="composition.inputs.is_public_prefecture"
                type="checkbox"
                name="pref"
                id="prefPublic"
              >
              <div class="lyOrder1 pSwitchBtn"></div>
              <p class="lyOrder0 lyMr4 pSwitchTitle pFont12 pFontBold">公開</p>
              <p class="lyOrder2 lyMl4 pSwitchTitle pFont12 pFontBold">非公開</p>
            </label>
          </dt>
          <dd class="lyPt4 lyPb4">
            <p
              v-for="error in ('prefecture' in composition.errors.value ? composition.errors.value.prefecture.filter(_error => _error !== '' && _error !== null) : [])"
              :key="error"
              class="lyMb8 pFont12 pFontBold pFontRed"
            >{{error}}</p>
            <div class="lyW150px">
              <div :class="{modSelectTrigger: true, isShow: isShowSelects}">
                <button
                  type="button"
                  id="pref"
                  class="lyPt12 lyPb12 lyPr8 lyPl8 lyVerticalBwCnt lyTextLeft lyW100 pFont16 pFontMedium pBackColorWhite pBorder1pxSolidGray20 pBorderRadius2px"
                  @click="isShowSelects = ! isShowSelects"
                >{{composition.inputs.prefecture === '' ? '未選択' : composition.inputs.prefecture}}<span class="lyW10px">
												<img
                          :src="siteUrl+'images/iconSelectBoxTriangle.svg'"
                          loading="lazy"
                          alt="ここを押して選択"
                        >
											</span>
                </button>
                <div class="modSelectListContainer">
                  <ul class="modSelectList">
                    <li class="pBorderBottom1pxSolidGray4">
                      <label class="lyW100 lyPt12 lyPb12 lyPr8 lyPl8 lyTextCnt pFontBold pHoverBackGray4 pAnimation pCursorPointer">
                        <input
                          v-model="composition.inputs.prefecture"
                          value=""
                          type="radio"
                          name="prefecture"
                          @change="isShowSelects = false"
                        >
                        未選択
                      </label>
                    </li>
                    <li
                      v-for="prefecture in PREFECTURES"
                      :key="prefecture"
                      class="pBorderBottom1pxSolidGray4"
                    >
                      <label class="lyW100 lyPt12 lyPb12 lyPr8 lyPl8 lyTextCnt pFontBold pHoverBackGray4 pAnimation pCursorPointer">
                        <input
                          v-model="composition.inputs.prefecture"
                          :value="prefecture"
                          type="radio"
                          name="prefecture"
                          @change="isShowSelects = false"
                        >
                        {{prefecture}}
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </dd>
        </dl>
      </div>
      <p
        v-if="'message' in composition.errors.value && typeof composition.errors.value.message === 'string' && composition.errors.value.message !== ''"
        class="lyMt4 pFont10 pFontMedium pFontRed"
      >{{composition.errors.value.message}}</p>
      <div class="lyHorizontalBwBreak lyFlexItemBreakBan lyTbFlexItemHorizontalRev lyPt4 lyPb4">
        <p class="lyW100 lyTbW50 lyMb16 lyTbMl8 lyTbMb0">
          <button
            type="submit"
            class="lyHvCnt lyW100 lyPt12 lyPb12 lyTextCnt pFont14 pFontBold pFontWhite pBackColorGreen pBorder1pxSolidGreen pBorderRadius2px pHoverSlideChange"
            @click="next"
          >次へ
          </button>
        </p>
        <p class="lyW100 lyTbW50 lyTbMr8">
          <button
            type="button"
            class="lyHvCnt lyW100 lyPt12 lyPb12 lyTextCnt pFont14 pFontBold pFontGreen pBorder1pxSolidGreen pBorderRadius2px"
            @click="emits('request-change', 'name')"
          >戻って編集
          </button>
        </p>
      </div>
    </div>
  </div>
</template>
