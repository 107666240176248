<script setup>
import {computed, inject} from "vue";
import useEntry, {USE_ENTRY_KEY} from "../EntryComposition";

const siteUrl = document.querySelector('meta[name="site-url"]').content;
const emits = defineEmits(['request-support', 'request-change']);

const composition = inject(USE_ENTRY_KEY, useEntry(siteUrl));

// 全てのエラーメッセージを一次元配列に変換
const errors = computed(() => Object.keys(composition.errors.value).map(key => composition.errors.value[key]).flat());

// 登録処理
const submit = async () => {
  // 登録を試みる
  const result = await composition.register();

  // 登録に成功した場合
  if (result) {
    window.location.href = siteUrl+'?entry=success';
  }
};
</script>

<template>
  <div>
    <ol class="lyHorizontalCnt lyMb16">
      <li class="lyColumnHorizontalCnt lyW100px lyMr4 lyMl4 lyPb20 isDisabled">
        <p class="lyMb4 pFont24 pFontBold">1</p>
        <p class="pFont10 pFontBold">メール認証</p>
      </li>
      <li class="lyColumnHorizontalCnt lyW100px lyMr4 lyMl4 lyPb20 isDisabled">
        <p class="lyMb4 pFont24 pFontBold">2</p>
        <p class="pFont10 pFontBold">アカウント情報入力</p>
      </li>
      <li class="lyColumnHorizontalCnt lyW100px lyMr4 lyMl4 lyPb20">
        <p class="lyMb4 pFont24 pFontBold">3</p>
        <p class="pFont10 pFontBold">入力情報確認</p>
      </li>
    </ol>
    <div class="lyW100 lyPd16 pFont14 pBackColorWhite pBorderRadius4px">
      <div class="lyPt4 lyPb4">
        <dl>
          <dt class="lyPt4 lyPb4 lyVerticalBwCnt">
            <div class="lyVerticalCnt">
              <p class="lyMr8 pFont12 pFontMedium">メールアドレス</p>
              <span class="lyPd4 lyMr8 pFont10 pFontBold pBackColorGray4 pBorderRadius2px">必須</span>
            </div>
          </dt>
          <dd class="lyPt4 lyPb4">
            <p class="lyPt12 lyPb12 lyPr8 lyPl8 pFont16 pFontMedium pBackColorGray4 pBorderRadius2px">{{composition.settings.email}}</p>
          </dd>
          <dt class="lyPt4 lyPb4 lyVerticalBwCnt">
            <div class="lyVerticalCnt">
              <p class="lyMr8 pFont12 pFontMedium">パスワード</p>
              <span class="lyPd4 lyMr8 pFont10 pFontBold pBackColorGray4 pBorderRadius2px">必須</span>
            </div>
          </dt>
          <dd class="lyPt4 lyPb4">
            <p class="lyPt12 lyPb12 lyPr8 lyPl8 pFont16 pFontMedium pBackColorGray4 pBorderRadius2px">●●●●●●●●●</p>
          </dd>
          <dt class="lyPt4 lyPb4 lyVerticalBwCnt">
            <div class="lyVerticalCnt">
              <p class="lyMr4 pFont12 pFontMedium">ニックネーム</p>
              <span class="lyPd4 lyMr8 pFont10 pFontBold pBackColorGray4 pBorderRadius2px">必須</span>
            </div>
          </dt>
          <dd class="lyPt4 lyPb4">
            <p class="lyPt12 lyPb12 lyPr8 lyPl8 pFont16 pFontMedium pBackColorGray4 pBorderRadius2px">{{composition.inputs.name}}</p>
          </dd>
          <dt class="lyPt4 lyPb4 lyVerticalBwCnt">
            <div class="lyVerticalCnt">
              <p class="lyMr8 pFont12 pFontMedium">生年月</p>
              <span class="lyPd4 lyMr8 pFont10 pFontBold pBackColorGray4 pBorderRadius2px">必須</span>
            </div>
          </dt>
          <dd class="lyPt4 lyPb4">
            <div class="lyVerticalCnt">
              <div class="lyVerticalCnt lyMr8">
                <p class="lyPt12 lyPb12 lyPr8 lyPl8 lyW60px lyTextRight pFont16 pFontMedium pBackColorGray4 pBorderRadius2px">{{composition.inputs.year}}</p>
                <p class="lyMl8 pFont12 pFontMedium">年</p>
              </div>
              <div class="lyVerticalCnt">
                <p class="lyPt12 lyPb12 lyPr8 lyPl8 lyW60px lyTextRight pFont16 pFontMedium pBackColorGray4 pBorderRadius2px">{{composition.inputs.month}}</p>
                <p class="lyMl8 pFont12 pFontMedium">月</p>
              </div>
            </div>
          </dd>
          <dt class="lyPt4 lyPb4 lyVerticalBwCnt">
            <div class="lyVerticalCnt">
              <p class="lyMr4 pFont12 pFontMedium">子ども情報</p>
              <span class="lyPd4 lyMr8 pFont10 pFontBold pBackColorGray4 pBorderRadius2px">任意</span>
              <p
                v-if="Number(composition.inputs.children_status) !== 0"
                class="pFont12 pFontBold pFontRed"
              >初回+{{new Intl.NumberFormat().format(composition.settings.p_child * 2)}}P</p>
            </div>
            <p class="pFont12 pFontBold pFontGray30">{{composition.inputs.is_public_children ? '公開' : '非公開'}}</p>
          </dt>
          <dd class="lyPt4 lyPb4">
            <ul class="lyHorizontalBreak">
              <li class="lyMr8 lyMt8">
                <label
                  class="lyInlineBlock lyMb16 modEllipseChip dGreen"
                  style="pointer-events: none"
                >
                  <input
                    v-model="composition.inputs.children_status"
                    type="radio"
                    name="userChildStatus"
                    value="0"
                    readonly
                  >
                  <span class="lyInlineBlock lyPt6 lyPb6 lyPr8 lyPl8 pFont14 pFontBold pFontWhite pBackColorGray30 pBorderRadiusEllipse pHoverOpacityDefault modEllipseChipBtn">未回答</span>
                </label>
              </li>
              <li class="lyMr8 lyMt8">
                <label
                  class="lyInlineBlock lyMb16 modEllipseChip dGreen"
                  style="pointer-events: none"
                >
                  <input
                    v-model="composition.inputs.children_status"
                    type="radio"
                    name="userChildStatus"
                    value="1"
                    readonly
                  >
                  <span class="lyInlineBlock lyPt6 lyPb6 lyPr8 lyPl8 pFont14 pFontBold pFontWhite pBackColorGray30 pBorderRadiusEllipse pHoverOpacityDefault modEllipseChipBtn">いる</span>
                </label>
              </li>
              <li class="lyMr8 lyMt8">
                <label
                  class="lyInlineBlock lyMb16 modEllipseChip dGreen"
                  style="pointer-events: none"
                >
                  <input
                    v-model="composition.inputs.children_status"
                    type="radio"
                    name="userChildStatus"
                    value="2"
                    readonly
                  >
                  <span class="lyInlineBlock lyPt6 lyPb6 lyPr8 lyPl8 pFont14 pFontBold pFontWhite pBackColorGray30 pBorderRadiusEllipse pHoverOpacityDefault modEllipseChipBtn">いない</span>
                </label>
              </li>
              <li class="lyMr8 lyMt8">
                <label
                  class="lyInlineBlock lyMb16 modEllipseChip dGreen"
                  style="pointer-events: none"
                >
                  <input
                    v-model="composition.inputs.children_status"
                    type="radio"
                    name="userChildStatus"
                    value="3"
                    readonly
                  >
                  <span class="lyInlineBlock lyPt6 lyPb6 lyPr8 lyPl8 pFont14 pFontBold pFontWhite pBackColorGray30 pBorderRadiusEllipse pHoverOpacityDefault modEllipseChipBtn">妊娠中</span>
                </label>
              </li>
              <li class="lyMr8 lyMt8">
                <label
                  class="lyInlineBlock lyMb16 modEllipseChip dGreen"
                  style="pointer-events: none"
                >
                  <input
                    v-model="composition.inputs.children_status"
                    type="radio"
                    name="userChildStatus"
                    value="4"
                    readonly
                  >
                  <span class="lyInlineBlock lyPt6 lyPb6 lyPr8 lyPl8 pFont14 pFontBold pFontWhite pBackColorGray30 pBorderRadiusEllipse pHoverOpacityDefault modEllipseChipBtn">妊活中</span>
                </label>
              </li>
            </ul>
            <dl v-if="composition.inputs.children.length > 0">
              <div
                v-for="(child, index) in composition.inputs.children"
                :key="index"
              >
                <dt class="lyPt8 lyPb8 lyVerticalCnt">
                  <p class="lyMr32 pFont12 pFontMedium"><span class="lyW24px lyMr4">{{index + 1}}</span>人目</p>
                  <p class="lyMr32 pFont12 pFontMedium">性別</p>
                  <p class="lyMr32 pFont12 pFontMedium">生年月</p>
                </dt>
                <dd class="lyPt4 lyPb4 lyHorizontal">
                  <p
                    v-if="Number(child.sex) === 1"
                    class="lyPt12 lyPb12 lyPr8 lyPl8 lyMr8 lyW100 lyTbW150px pFont16 pFontMedium pBackColorGray4 pBorderRadius2px"
                  >男の子</p>
                  <p
                    v-else-if="Number(child.sex) === 2"
                    class="lyPt12 lyPb12 lyPr8 lyPl8 lyMr8 lyW100 lyTbW150px pFont16 pFontMedium pBackColorGray4 pBorderRadius2px"
                  >女の子</p>
                  <p
                    v-else
                    class="lyPt12 lyPb12 lyPr8 lyPl8 lyMr8 lyW100 lyTbW150px pFont16 pFontMedium pBackColorGray4 pBorderRadius2px"
                  >未選択</p>
                  <div class="lyVerticalCnt lyMr8">
                    <p class="lyPt12 lyPb12 lyPr8 lyPl8 lyW60px lyTextRight pFont16 pFontMedium pBackColorGray4 pBorderRadius2px">{{child.year}}</p>
                    <p class="lyMl8 pFont12 pFontMedium">年</p>
                  </div>
                  <div class="lyVerticalCnt">
                    <p class="lyPt12 lyPb12 lyPr8 lyPl8 lyW60px lyTextRight pFont16 pFontMedium pBackColorGray4 pBorderRadius2px">{{child.month}}</p>
                    <p class="lyMl8 pFont12 pFontMedium">月</p>
                  </div>
                </dd>
              </div>
            </dl>
          </dd>
          <dt class="lyPt4 lyPb4 lyVerticalBwCnt">
            <div class="lyVerticalCnt">
              <p class="lyMr4 pFont12 pFontMedium">住まい</p>
              <span class="lyPd4 lyMr8 pFont10 pFontBold pBackColorGray4 pBorderRadius2px">任意</span>
              <p
                v-if="composition.inputs.prefecture !== ''"
                class="pFont12 pFontBold pFontRed"
              >初回+{{new Intl.NumberFormat().format(composition.settings.p_pref * 2)}}P</p>
            </div>
            <p class="pFont12 pFontBold pFontGray30">{{composition.inputs.is_public_prefecture ? '公開' : '非公開'}}</p>
          </dt>
          <dd class="lyPt4 lyPb4">
            <p class="lyPt12 lyPb12 lyPr8 lyPl8 lyW150px pFont16 pFontMedium pBackColorGray4 pBorderRadius2px">{{composition.inputs.prefecture}}</p>
          </dd>
          <dt class="lyPt4 lyPb4 lyVerticalBwCnt">
            <div class="lyVerticalCnt">
              <p class="lyMr4 pFont12 pFontMedium">興味のあるカテゴリーを選択</p>
              <span class="lyPd4 lyMr8 pFont10 pFontBold pBackColorGray4 pBorderRadius2px">任意</span>
              <p
                v-if="composition.inputs.interesting.length > 0"
                class="pFont12 pFontBold pFontRed"
              >初回+{{new Intl.NumberFormat().format(composition.settings.p_int * 2)}}P</p>
            </div>
          </dt>
          <dd class="lyPt4 lyPb4">
            <ul class="lyHorizontalBreak">
              <li
                v-for="category in composition.settings.categories"
                :key="category.id"
                class="lyMr8 lyMt8 lyMb8"
              >
                <label
                  class="lyInlineBlock modEllipseChip dGreen"
                  style="pointer-events: none"
                >
                  <input
                    :checked="composition.inputs.interesting.includes(category.id)"
                    :value="category.id"
                    type="checkbox"
                    name="interesting"
                    readonly
                  >
                  <span class="lyInlineBlock lyPt6 lyPb6 lyPr8 lyPl8 pFont14 pFontBold pFontWhite pBackColorGray30 pBorderRadiusEllipse pHoverOpacityDefault modEllipseChipBtn">{{category.name}}</span>
                </label>
              </li>
            </ul>
          </dd>
        </dl>
      </div>
      <p class="lyVerticalCnt lyPt4 lyPb4 pFont12">
        <span class="lyPd4 lyMr8 pFont10 pFontBold pBackColorGray4 pBorderRadius2px">必須</span>
        <label class="lyHorizontal pCheckBox pHoverOpacityDefault pAnimation">
          <p class="lyMr8">利用規約・プライバシーポリシーに同意する</p>
          <input
            v-model="composition.inputs.agree"
            type="checkbox"
            name="is_receive_mail_magazine"
          >
          <span class="lyShrinkNone pCheckBoxBtn"></span>
        </label>
      </p>
      <ul class="lyPt8 lyPb8 lyMb16 pFont12">
        <li class="lyPt4 lyPb4">※「@tetsunagi-p.com」からのメールを受け取れるように設定お願いします。</li>
        <li class="lyPt4 lyPb4">※ご登録いただいたメールアドレス宛にメールマガジンをお送りします。希望されない場合はチェックを外してください。
          <div class="lyPt8">
            <label class="lyHorizontal pCheckBox pHoverOpacityDefault pAnimation">
              <p class="lyMr8">メールマガジン配信を希望する</p>
              <input
                v-model="composition.inputs.is_receive_mail_magazine"
                type="checkbox"
                name="is_receive_mail_magazine"
              >
              <span class="lyShrinkNone pCheckBoxBtn"></span>
            </label>
          </div>
        </li>
        <li class="lyPt4 lyPb4">※配信設定は会員登録後にマイページにて変更いただけます。</li>
      </ul>
      <p
        v-for="error in errors"
        :key="error"
        class="lyMb8 pFont12 pFontBold pFontRed"
      >{{error}}</p>
      <div class="lyHorizontalBwBreak lyFlexItemBreakBan lyTbFlexItemHorizontalRev lyPt4 lyPb4">
        <p class="lyW100 lyTbW50 lyMb16 lyTbMl8 lyTbMb0">
          <button
            type="submit"
            class="lyHvCnt lyW100 lyPt12 lyPb12 lyTextCnt pFont14 pFontBold pFontWhite pBackColorGreen pBorder1pxSolidGreen pBorderRadius2px pHoverSlideChange"
            @click="submit"
          >登録する</button>
        </p>
        <p class="lyW100 lyTbW50 lyTbMr8">
          <button
            type="button"
            class="lyHvCnt lyW100 lyPt12 lyPb12 lyTextCnt pFont14 pFontBold pFontGreen pBorder1pxSolidGreen pBorderRadius2px"
            @click="emits('request-change', 'categories')"
          >戻って編集</button>
        </p>
      </div>
    </div>
  </div>
</template>
