<script setup>
import {computed} from "vue";

const props = defineProps({
  isShow: {
    type: Boolean,
    required: true,
  },
  layerDepth: {
    type: Number,
    default: 1,
  },
});
const emits = defineEmits(['request-close']);

// 追加クラス
const style = computed(() => {
  if (props.layerDepth === 2) {
    return 'z-index:301;';
  }
  if (props.layerDepth === 3) {
    return 'z-index:303;';
  }
  return '';
});
</script>

<template>
  <div
    :class="{gbModalBack: true, isShow: props.isShow}"
    :style="style"
    @click="emits('request-close')"
  ></div>
</template>
