import {createApp} from 'vue';
import App from "./App.vue";

createApp(App).mount('#js-authModal');

// ログインボタンをクリックした際の処理
document.addEventListener('click', event => {
  // ログイン
  if ((event.target instanceof HTMLElement) && event.target.closest('.js-login-comment')) {
    window.dispatchEvent(new CustomEvent('openLoginModalComment'));
  }
  else if ((event.target instanceof HTMLElement) && event.target.closest('.js-login')) {
    window.dispatchEvent(new CustomEvent('openLoginModal'));
  }

  // 新規登録
  if ((event.target instanceof HTMLElement) && event.target.closest('.js-register')) {
    window.dispatchEvent(new CustomEvent('openEntryModal'));
  }
});
